



























































import { Component, Vue } from "vue-property-decorator";
import { IBuildingCreate } from "@/interfaces";
import { dispatchCreateBuilding, dispatchGetProject } from "@/store/main/actions";
import { readProject } from '@/store/main/getters';

@Component
export default class Create extends Vue {
  public loading = true;
  public valid = false;

  public building: IBuildingCreate = {
    name: "",
    address: "",
    project_id: 0,
    position: 0,
  };

  public reset() {
    this.building = <IBuildingCreate>{};
    this.$validator.reset();
  }

  public cancel() {
    this.$router.back();
  }

  get project(){
    return readProject(this.$store);
  }

  public async mounted() {
    await dispatchGetProject(this.$store, Number(this.$router.currentRoute.params.id));
    this.loading = false;
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      this.building.project_id = Number(this.$router.currentRoute.params.id);
      await dispatchCreateBuilding(this.$store, this.building);
      this.$router.back();
    }
  }
}
